import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Button from 'Components/@Base/Button';
import { cnb } from 'cnbuilder';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import LanguageSelector from 'Components/Header/Components/LanguageSelector';

import styles from './Header.module.scss';

import logo from 'Assets/logos/om-logo.svg';

const Header = ({ scrollRef }) => {
  const { t } = useTranslation();
  const [source, setSource] = useState(null);
  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isDesktop) {
      setSource('/videos/hero_loop.mp4');
    } else {
      setSource('');
    }
  }, [isDesktop]);

  return (
    <header className={styles.Header}>
      <Container fluid className={styles.Logo}>
        <img src={logo} alt="OneMillion Logo" />
        <div className={styles.LanguageSelector}>
          <LanguageSelector />
        </div>
      </Container>
      <Container className={styles.Container}>
        <Row className={cnb(styles.Container__Row, 'justify-content-center justify-content-lg-start')}>
          <Col lg={8}>
            <h2>
              {t('Co najmniej milion')} <br /> {t('powodów aby dołączyć do')} <br/> <span>{t('OneMillion.com')}</span>
            </h2>
            <Row className="justify-content-center">
              <Button element="a" className="w-auto" variant="primary" onClick={executeScroll}>
                {t('Dowiedz się więcej')}
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className={styles.Video__wrapper}>
            <video key={source} muted loop autoPlay preload="auto">
              <source src={source} type="video/mp4" />
            </video>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
