import React from 'react';
import { cnb } from 'cnbuilder';
import { useTranslation } from 'react-i18next';

import Button from 'Components/@Base/Button';

import styles from './Solution.module.scss';

const Solution = ({ className, bonus, tier, amount, amountReceived }) => {  
  const { t } = useTranslation();

  return (
    <div className={cnb(styles.CouponHeight, 'col-lg-6 col-xl-3')} data-aos="zoom-in" data-aos-duration="1000">
      <div className={cnb(styles.Coupon, className)}>
        <div className={styles.Coupon__bonus}>
          <div className={styles.Coupon__bonus__content}>
            <span className={styles.Coupon__bonus__content__value}>{bonus}</span>
          </div>
        </div>

        <p className={styles.Coupon__amount} dangerouslySetInnerHTML={{__html: t(amount)}}></p>

        <Button
          rel="noreferrer noopener"
          className={styles.Coupon__button}
        >
          {t(tier)}
        </Button>
      </div>
    </div>
  );
};

export default Solution;
