import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANGUAGE = 'en-GB';
export const LANGUAGES = ['en-GB', 'de-DE', 'pl-PL', 'es-ES', 'pt-PT'];

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
    fallbackLng: DEFAULT_LANGUAGE,
    otherLanguages: LANGUAGES.filter((l) => l !== DEFAULT_LANGUAGE),
    whitelist: LANGUAGES,
    debug: false,
    keySeparator: '_',
    nsSeparator: '|',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
