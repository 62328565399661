import React from 'react';
import { cnb } from 'cnbuilder';
import { useTranslation } from 'react-i18next';

import shapeGroup1 from 'Assets/images/shape_group_1.png';
import shape1 from 'Assets/images/shape_1.png';

import Solution from 'Components/Solutions/components/Solution';

import styles from './Solutions.module.scss';

const COUPONS = [
  {
    id: 0,
    className: 'my-5 mb-sm-0 mt-lg-0',
    bonus: '1',
    tier: 'E-commerce',
    amount: '5 miliardów użytkowników z obrotem rocznym na ponad 7&nbsp;bilionów dolarów',
    amountReceived: '',
  },
  {
    id: 1,
    className: 'mb-5 mb-lg-0',
    bonus: '2',
    tier: 'Social Media',
    amount: '5,2 miliarda użytkowników, którzy wydali ponad 600 miliardów dolarów tylko na reklamy i pozycjonowanie',
    amountReceived: '',
  },
  {
    id: 3,
    className: 'mb-5 mb-lg-0',
    bonus: '3',
    tier: 'Gry',
    amount: 'Gry mobilne to najprężniej rozwijający się segment gier cyfrowych, przekraczający 130 miliardów dolarów',
    amountReceived: '',
  },
  {
    id: 4,
    className: 'mb-5 mb-lg-0',
    bonus: '4',
    tier: 'Edukacja',
    amount: 'Statystyki pokazują, że wartość globalnego rynku e-learningu szacowana jest na 500 miliardów dolarów do 2028 roku',
  }
];

const Solutions = () => {  
  const { t } = useTranslation();

  return (
    <div className={cnb(styles.Coupons, 'container-fluid position-relative')}>
      <div className={cnb(styles.ShapeGroup1, 'parallax-shape')}>
        <img src={shapeGroup1} alt="shape group 1" />
      </div>
 
      <div className={cnb(styles.Shape1, 'parallax-shape')}>
        <img src={shape1} alt="shape 1" />
      </div>
      
      <div className="row">
        <div className="container">
        <h2 data-aos="zoom-out" data-aos-duration="1000" className={styles.Title}>{t('Internet w liczbach')}</h2>

          <div className="row">
            <div className={styles.Centered}>
              {COUPONS.map((coupon) => (
                <Solution key={coupon.id} {...coupon} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
