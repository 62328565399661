/**
 *
 * @param {string} str
 * @returns {string}
 */
export const getCountryBaseIso = (str) => str.split('-')[0].toLowerCase();

/**
 *
 * @param {string} str
 * @returns {string}
 */
export const getCountryIso = (str) => str.split('-')[1].toLowerCase();
