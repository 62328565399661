import React, { useState, useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { useTranslation } from 'react-i18next';

import { getCountryIso } from 'Utils/common';

import styles from './Platforms.module.scss';

import PLATFORMS from './platformsData';

SwiperCore.use([Pagination, Autoplay]);

const images = ['ringEntertainment', 'ringEducation', 'ringBusiness', 'ringFinances'];

const Platforms = (props) => {
  const { t, i18n } = useTranslation();
  const [swiper, setSwiper] = useState(null);
  const [index, setIndex] = useState(0);
  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 30,
    autoHeight: true,
    pagination: {
      clickable: true,
    },
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1000,
  };

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', () => setIndex(swiper.realIndex));
    }

    return () => {
      if (swiper) {
        swiper.off('slideChange', () => setIndex(swiper.realIndex));
      }
    };
  }, [swiper]);

  return (
    <div className={styles.Platforms} ref={props.scrollRef}>
      <Container>
        <Row>
          <Col xl={6}>
            <Container>
              <Row
                className={styles.Platforms__ring}
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="150"
              >
                {images.map((e, i) => {
                  return (
                    <img
                      key={i}
                      src={`/images/ring/${e + getCountryIso(i18n.language).toUpperCase()}.png`}
                      alt="OneMillion Ecosystem"
                      className={i === index ? 'opacity-1' : undefined}
                    />
                  );
                })}
              </Row>
            </Container>
          </Col>
          <Col xl={6}>
            <div
              className={styles.Platforms__swiper}
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="150"
            >
              <Swiper {...swiperParams} onSwiper={setSwiper}>
                {PLATFORMS.map(({ id, name, description }) => {
                  return (
                    <SwiperSlide key={id}>
                      <h4>{t(name)}</h4>
                      <p>{t(description)}</p>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Platforms;
