import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getCountryIso } from 'Utils/common';

import styles from './Footer.module.scss';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container className={styles.Footer} fluid>
      <Row className={styles.Footer__topWrapper}>
        <Container>
          <Row>
            <Col lg={4}>
              <div className={styles.Footer__details}>
                <h6>OneMillion.com PSA</h6>
                <p>ul. Warszawska 40/2A</p>
                <p>40-008 Katowice</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.Footer__logo}>
                <svg
                  width="143"
                  height="27"
                  viewBox="0 0 143 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.Footer__logo__shapeLeft}
                >
                  <path
                    d="M0 6h45.984a20 20 0 0112.494 4.383l14.044 11.234A20 20 0 0085.016 26H143"
                    stroke="#737373"
                    strokeWidth="1.2"
                  ></path>
                  <path
                    d="M11 1h35.984a20 20 0 0112.494 4.383l14.044 11.234A20 20 0 0086.016 21H114"
                    stroke="#737373"
                    strokeWidth="1.2"
                  ></path>
                </svg>
                <svg width="43" height="80" viewBox="0 0 43 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.964 5.242c-1.611-2.097-3.323-3.246-4.974-3.246C6.847 1.996 0 17.601 0 40c0 22.4 6.847 38.004 12.99 38.004 1.651 0 3.363-1.149 4.974-3.246C13.191 67.883 9.969 54.9 9.969 40c0-14.9 3.222-27.883 7.995-34.758z"
                    fill="#B67C2B"
                  ></path>
                  <path
                    d="M42.111 39.173C41.668 10.101 32.203.706 28.195.081L26.845 0H14.42c3.947.504 15.87 11.815 15.628 40 .242 28.186-11.68 39.516-15.628 40h12.426l1.349-.08c4.028-.646 13.473-10.02 13.916-39.093V40c.02-.262.02-.544 0-.827z"
                    fill="#D99628"
                  ></path>
                </svg>
                <svg
                  width="143"
                  height="27"
                  viewBox="0 0 143 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.Footer__logo__shapeRight}
                >
                  <path
                    d="M0 6h45.984a20 20 0 0112.494 4.383l14.044 11.234A20 20 0 0085.016 26H143"
                    stroke="#737373"
                    strokeWidth="1.2"
                  ></path>
                  <path
                    d="M11 1h35.984a20 20 0 0112.494 4.383l14.044 11.234A20 20 0 0086.016 21H114"
                    stroke="#737373"
                    strokeWidth="1.2"
                  ></path>
                </svg>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.Footer__info}>
                <h6>{t('Polityka prywatności')}:</h6>
                <a
                  className={styles.Button}
                  href={`/privacyPolicy${getCountryIso(i18n.language).toUpperCase()}.pdf`}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Pobierz')}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Footer;
