import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import { getCountryIso } from 'Utils/common';

import Icon from 'Components/Header/Components/LanguageSelector/Components/Icon';
import Dropdown from 'Components/Header/Components/LanguageSelector/Components/Dropdown';

import styles from './LanguageSelector.module.scss';

import { LANGUAGES } from 'config';
import { ReactComponent as ArrowDown } from 'Assets/images/svg/arrow_down.svg';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const header = (
    <>
      <div className={styles.LanguageSelector__icon}>
        <ReactSVG src={`/images/flags/${getCountryIso(i18n.language)}.svg`} />
      </div>
      <span className={styles.LanguageSelector__name}>
        {LANGUAGES?.find((l) => l.locale === i18n.language)?.name ?? ''}
      </span>
      <Icon className="ml-lg-3">
        <ArrowDown style={{ height: '20px', width: '20px' }} />
      </Icon>
    </>
  );

  return (
    <>
      {i18n && (
        <Dropdown className={styles.LanguageSelector} header={header}>
          {LANGUAGES.map(({ id, locale, name }) => {
            if (locale === i18n.language) {
              return null;
            }

            return (
              <button key={id} type="button" onClick={() => i18n.changeLanguage(locale)}>
                <div className={styles.LanguageSelector__icon}>
                  <ReactSVG src={`/images/flags/${getCountryIso(locale)}.svg`} />
                </div>
                <span className={styles.LanguageSelector__name}>{name}</span>
              </button>
            );
          })}
        </Dropdown>
      )}
    </>
  );
};

export default LanguageSelector;
