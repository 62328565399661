export const monxPrice = {
  price: '1,22',
  changeTime: '2021-03-01',
};

export const LANGUAGES = [
  {
    id: 2,
    locale: 'en-GB',
    name: 'English',
  },
  {
    id: 3,
    locale: 'es-ES',
    name: 'Español',
  },
  {
    id: 1,
    locale: 'de-DE',
    name: 'Deutsch',
  },
  {
    id: 0,
    locale: 'pl-PL',
    name: 'Polski',
  },
  {
    id: 4,
    locale: 'pt-PT',
    name: 'Português'
  }
];

//phoneHref musi być z kreskami co trzecia cyfra żeby a href zadziałał dobrze!!! np. +48-123-456-789

export const REFERRALS = [
  {
    ref: 'MIKE',
    phone: '+48 730567890',
    phoneHref: '+48-730-567-890',
    email: 'mike@onemillion.com',
    telegram: 'https://t.me/MrDx2', 
 },
];
