const PLATFORMS = [
  {
    id: 0,
    name: 'Strefa: Rozrywkowa',
    description:
      'Strefa rozrywkowa to idealny sposób na relaks. Szeroki wachlarz i rónorodność jakie oferuje przyciągnie niejednego fana gier, filmów i seriali. Znajdzie się tu również coś dla koneserów muzyki oraz sztuki zarówno tej tradycyjnej jak i tej nowoczesnej.',
  },
  {
    id: 1,
    name: 'Strefa: Edukacyjna',
    description:
      'W tej strefie znajdziemy wiele przydatnych zasobów takich jak kursy i szkolenia z różnych dziedzin, wykłady i webinary prestiżowych uczelni, ebooki i artykuły naukowe jak również fora czy grupy dyskusyjne, na których będziemy mogli zadać wiele nurtujących pytań',
  },
  {
    id: 2,
    name: 'Strefa: Biznesowa',
    description:
      'Miejsce dla przedsiębiorców, którzy poznali już zalety prowadzenia swoich działalności w internecie i również w naszym ekosystemie widzą tą ogromną wartość. Strefa biznesowa to jeden wielki marketplace, w którym użytkownicy mogą skorzystać z wielu usług i rozwiązań jakie proponuje im rynek.',
  },
  {
    id: 3,
    name: 'Strefa: Finansowa',
    description:
      'Strefa finansowa jest kluczowym element przynoszącym korzyści zarówno jednostkom jak i przedsiębiorcą. Możliwości jakie daje dzięki swojej szybkości, efektywności oraz innowacyjności pozwalają na skuteczne zwiększenie działań i rozwoju w wielu jej obszarach.',
  },
];

export default PLATFORMS;
