import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './Description.module.scss';
import Cta from 'Components/Cta';

const Description = ({ scrollRef, sectionsScrollRef }) => {
  const { t } = useTranslation();

  return (
    <>
      <div ref={scrollRef} className={styles.Description}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2 data-aos="zoom-out" data-aos-duration="1000">
                {t('Czym jest OneMillion?')}
              </h2>
              
              <Cta scrollRef={sectionsScrollRef} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Description;
