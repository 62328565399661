import React from 'react';
import { cnb } from 'cnbuilder';
import { useTranslation } from 'react-i18next';

import Button from 'Components/@Base/Button';

import styles from './Coupon.module.scss';

const Coupon = ({ className, bonus, tier, amount, amountReceived }) => {
  const { t } = useTranslation();
  
  return (
    <div className="col-lg-6 col-xl-3" data-aos="zoom-in" data-aos-duration="1000">
      <div className={cnb(styles.Coupon, className)}>
        <div className={styles.Coupon__bonus}>
          <div className={styles.Coupon__bonus__content}>
            <span className={styles.Coupon__bonus__content__value}>@</span>
          </div>
        </div>

        <p className={styles.Coupon__amount}>{t(amount)}</p>

        <p className={styles.Coupon__amountReceived}>{t(tier)}</p>

        <Button
          rel="noreferrer noopener"
          className={styles.Coupon__button}
          onClick={() => {
            window.open(`mailto:${bonus}`);
          }}
        >
          {t('Napisz do nas')}
        </Button>
      </div>
    </div>
  );
};

export default Coupon;
