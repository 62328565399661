import PropTypes from 'prop-types';
import React from 'react';
import { cnb } from 'cnbuilder';

import styles from './Button.module.scss';

const Button = ({ element: Element, className, children, variant, size, ...rest }) => (
  <Element className={cnb(styles.Button, styles[`Button_${variant}`], styles[`Button_${size}`], className)} {...rest}>
    {children}
  </Element>
);

Button.propTypes = {
  element: PropTypes.oneOf(['a', 'button']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'transparentWithBorder']),
  size: PropTypes.oneOf(['medium']),
};

Button.defaultProps = {
  element: 'button',
  className: '',
  type: 'button',
  variant: 'primary',
  size: 'medium',
};

export default Button;
