import React from 'react';
import { cnb } from 'cnbuilder';

import shapeGroup1 from 'Assets/images/shape_group_1.png';
import shape1 from 'Assets/images/shape_1.png';

import Coupon from 'Components/Coupons/components/Coupon';

import styles from './Coupons.module.scss';

const COUPONS = [
  {
    id: 0,
    className: 'my-5 mb-sm-0 mt-lg-0',
    bonus: 'hello@onemillion.com',
    tier: 'Kontakt w sprawie ogólnych informacji',
    amount: 'Kontakt',
    amountReceived: '',
  },
  {
    id: 1,
    className: 'mb-5 mb-lg-0',
    bonus: 'media@onemillion.com',
    tier: 'Kontakt dla mediów',
    amount: 'Media',
    amountReceived: '',
  }
];

const Coupons = () => {  
  return (
    <div className={cnb(styles.Coupons, 'container-fluid position-relative')}>
      <div className={cnb(styles.ShapeGroup1, 'parallax-shape')}>
        <img src={shapeGroup1} alt="shape group 1" />
      </div>

      <div className={cnb(styles.Shape1, 'parallax-shape')}>
        <img src={shape1} alt="shape 1" />
      </div>

      <div className="row">
        <div className="container">
          <div className="row">
            <div className={styles.Centered}>
              {COUPONS.map((coupon) => (
                <Coupon key={coupon.id} {...coupon} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
