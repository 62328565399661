import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { cnb } from 'cnbuilder';

import styles from './Mechanism.module.scss';

const MECHANISMS = [
    {
        id: 0,
        title: 'Wynajem powierzchni',
        description: 'Każdy użytkownik ma możliwość zaprezentowania swojego biznesu jak i swojej marki osobistej w nowej unikalnej formie. W wirtualnym mieście podzielonym na poszczególne strefy oraz dzielnice można pozycjonować się na 3 różne sposoby. Od unikalnej wizytówki, przez lidera AirDrop, aż po własną przestrzeń 3D.'
    },
    {
        id: 1,
        title: 'Marketplace',
        description: 'Cyfrowy marketplace zintegrowany z każdą strefą pozwala dotrzeć do wszystkich użytkowników z spersonalizowaną ofertą w czasie rzeczywistym. Dodatkowo wykorzystanie technologii metaverse daje potencjał do rewolucjonizacji sposobu, w jaki postrzegamy zakupy i interakcje, czyniąc je bardziej osobistymi i angażującymi.'
    },
    {
        id: 2,
        title: 'Program Partnerski',
        description: 'Rozbudowany program partnerski z unikalnym linkiem afiliacyjnym każdego zarejestrowanego użytkownika to idealne narzędzie pozwalające zwiększać zasięg promowanych marek i usług jak również dające możliwość generowania pasywnego dochodu. Dzięki możliwości połączenia współpracy użytkowników i promowana ich ulubionych produktów dzielnica w każdej ze stref pozwala na zwiększenie rangi dzięki czemu każdy zyskuje.'
    },
    {
        id: 3,
        title: 'Drop Hunters',
        description: 'Tworzenie zaangażowanej społeczności, która ma szansę odkrywać promowane marki, to skuteczny mechanizm, który oprócz samej promocji przyciąga nowych potencjalnych klientów i zwiększa ich świadomość na temat oferowanych produktów oraz usług.'
    }
]

const Mechanism = () => {
    const sliderRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (sliderRef.current) {
            setTimeout(() => {
                sliderRef.current.swiper.update(); // Force update
            }, 800);
        }
    }, []);

    return (
        <div className={cnb(styles.Mechanisms, 'container-fluid')}>
            <div className="row">
                <div className="container" data-aos="fade-left" data-aos-duration="1000">
                    <Swiper 
                        ref={sliderRef}
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                            el: '.swiper-pagination',
                        }}
                        allowTouchMove={false}
                        className={styles.swiper}
                    >
                        {MECHANISMS.map((mechanism) => (
                            <SwiperSlide key={mechanism.id}>
                                <div className={styles.Mechanism}>
                                    <h2>{t(mechanism.title)}</h2>
                                    <p>{t(mechanism.description)}</p>
                                </div>     
                            </SwiperSlide>
                        ))}
                        <div className="swiper-pagination"></div>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
    

export default Mechanism;