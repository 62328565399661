import PropTypes from 'prop-types';
import React from 'react';
import { cnb } from 'cnbuilder';

const Icon = ({ className, children }) => (
  <figure className={cnb(className, 'd-inline-flex align-items-center justify-content-center mb-0')}>{children}</figure>
);

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
