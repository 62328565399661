import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ctaPattern from 'Assets/images/cta_pattern.svg';

import styles from './Cta.module.scss';

const Cta = ({ scrollRef }) => {
  const { t } = useTranslation();

  const executeScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container className={styles.cta} fluid data-aos="zoom-out" data-aos-duration="1000">
      <Row>
        <Container>
          <Row>
            <div className={styles['cta-block']}>
              <img src={ctaPattern} alt="Odbierz za darmo i zarabiaj!" />
              <div className={styles['cta-block__content']}>
                <p>
                  {t(
                    'Zawieszony w przestrzeni kosmicznej złoty pierścień z wirtualną metropolią podzieloną na strefy edukacyjne, rozrywkowe, finansowe oraz biznesowe redefiniuje nasze postrzeganie przestrzeni w internecie, otwierając nowe możliwości w jednym spójnym środowisku.',
                  )}
                </p>
                <button onClick={executeScroll}>{t('Zobacz strefy')}</button>
              </div>
            </div>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Cta;
