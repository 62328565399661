import 'Styles/global.scss';

import React, { useEffect, useRef } from 'react';

import Aos from 'aos';

import Header from 'Components/Header';
import Description from 'Components/Description';
import Platforms from 'Components/Platforms';
import Footer from 'Components/Footer';
import CtaBox from 'Components/CtaBox';
import Coupons from 'Components/Coupons';
import Video from 'Components/VIdeo/VIdeo';
import Solutions from 'Components/Solutions';
import Mechanism from 'Components/Mechanism/Mechanism';

function App() {
  const scrollRef = useRef(null);
  const sectionsScrollRef = useRef(null);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Header scrollRef={scrollRef} />
      <main>
        <Description scrollRef={scrollRef} sectionsScrollRef={sectionsScrollRef} />
        <Platforms scrollRef={sectionsScrollRef} />
        <CtaBox />
        <Mechanism />
        <Solutions />
        <Video />
        <Coupons />
      </main>
      <Footer />
    </>
  );
}

export default App;
