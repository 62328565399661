import React from 'react';
import { cnb } from 'cnbuilder';

import { ReactComponent as ShapeTL } from 'Assets/icons/shape_top-left.svg';
import { ReactComponent as ShapeTM } from 'Assets/icons/shape_top-middle.svg';
import { ReactComponent as ShapeTR } from 'Assets/icons/shape_top-right.svg';
import { ReactComponent as ShapeBR } from 'Assets/icons/shape_bottom-right.svg';
import { ReactComponent as ShapeBL } from 'Assets/icons/shape_bottom-left.svg';

import styles from './Video.module.scss';
import { useTranslation } from 'react-i18next';

const Shape = ({ className, children }) => <figure className={cnb(styles.Shape, className)}>{children}</figure>;

const Video = () => {
  const { t } = useTranslation();

  return (
    <div className={cnb(styles.Container, 'container-fluid')}>
      <div className="row">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10" style={{ overflow: 'hidden' }}>
              <h2 className={styles.Title} data-aos="zoom-out" data-aos-duration="1000">{t('Zobacz wizualizację')}</h2>

              <div className={styles.Frame}>
                <span className={cnb(styles.Dot, styles.Dot_TL)} />
                <span className={cnb(styles.Dot, styles.Dot_TR)} />
                <span className={cnb(styles.Dot, styles.Dot_BR)} />
                <span className={cnb(styles.Dot, styles.Dot_BL)} />

                <Shape className={styles.Shape_TL}>
                  <ShapeTL />
                </Shape>
                <Shape className={styles.Shape_TM}>
                  <ShapeTM />
                </Shape>
                <Shape className={styles.Shape_TR}>
                  <ShapeTR />
                </Shape>
                <Shape className={styles.Shape_BR}>
                  <ShapeBR />
                </Shape>
                <Shape className={styles.Shape_BL}>
                  <ShapeBL />
                </Shape>

                <div className={styles.FrameContent} data-aos="zoom-in" data-aos-duration="1000">
                  <div className={styles.ContentWrapper}>
                  <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/4SjOW56nwnM?si=ES9HXKvc0yrNaM2v&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)};

export default Video;
