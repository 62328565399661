import PropTypes from 'prop-types';
import React, { Children, cloneElement, useCallback, useState, useRef } from 'react';
import { cnb } from 'cnbuilder';
import Collapse from '@kunukn/react-collapse';

import useEventListener from 'Hooks/use-event-listener';

import styles from './Dropdown.module.scss';

const Dropdown = ({ className, header, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collapseState, setCollapseState] = useState('');
  const dropdownRef = useRef(null);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  const handleClickOutside = useCallback(
    (event) => {
      const { target } = event;
      const { current: dropdown } = dropdownRef;

      if (dropdown && isOpen && !dropdown.contains(target)) {
        handleToggle();
      }
    },
    [dropdownRef, isOpen, handleToggle],
  );

  const handleOnChange = ({ state }) => {
    setCollapseState(state);
  };

  useEventListener('click', handleClickOutside);

  return (
    <div
      ref={dropdownRef}
      className={cnb(styles.Dropdown, isOpen && styles.Dropdown_isExpanded, className, collapseState)}
    >
      <button className={styles.Dropdown__header} type="button" onClick={handleToggle}>
        {header}
      </button>
      <Collapse className={styles.Dropdown__collapse} isOpen={isOpen} onChange={handleOnChange}>
        <div className={styles.Dropdown__content}>
          {Children.map(children, (child) => {
            return child
              ? cloneElement(child, {
                  onClick: () => {
                    handleToggle();

                    if (typeof child.props.onClick === 'function') {
                      child.props.onClick();
                    }
                  },
                })
              : null;
          })}
        </div>
      </Collapse>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  header: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  className: '',
};

export default Dropdown;
